import { connect } from 'react-redux'

import ContentDesk from 'components/content_desk_new/'
import {
  fetchKpiStatisticsStart,
  search,
  setLimit,
  refreshContentsStatusStart,
  stopRefreshContentsStatus
} from 'actions/content_desk'
import {
  getCDeskCalendarCampaigns,
  getCDeskCalendarContents,
  getCDeskCampaigns,
  getCDeskConfig,
  getCDeskContentIsLoading,
  getCDeskContents
} from 'selectors'

const mapStateToProps = state => ({
  contents: getCDeskContents(state),
  campaigns: getCDeskCampaigns(state),
  calendarContents: getCDeskCalendarContents(state),
  calendarCampaigns: getCDeskCalendarCampaigns(state),
  isContentLoading: getCDeskContentIsLoading(state),
  hasDomain: !!getCDeskConfig(state).get('domain') || false
})

export default connect(
  mapStateToProps,
  {
    fetchKpi: fetchKpiStatisticsStart,
    search,
    setLimit,
    refreshContentsStatusStart,
    stopRefreshContentsStatus
  }
)(ContentDesk)
