import { connect } from 'react-redux'

import EmailTemplatesLibrary from 'components/email_templates_library'

import { getCDeskConfig } from 'selectors'

const mapStateToProps = state => ({
  hasDomain: !!getCDeskConfig(state).get('domain') || false
})

export default connect(
  mapStateToProps,
  {}
)(EmailTemplatesLibrary)
