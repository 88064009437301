import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import CloseIcon from '@mui/icons-material/Close'
import { TextField, InputAdornment, IconButton } from '@mui/material'

const Reset = forwardRef(({ handleChange, ...props }, ref) => {
  const InputProps = {
    ...(props.InputProps || {}),
    endAdornment: (
      <>
        {props.InputProps.endAdornment}

        <InputAdornment position="end">
          <IconButton onClick={() => handleChange(null)}>
            <CloseIcon />
          </IconButton>
        </InputAdornment>
      </>
    )
  }

  return (
    <TextField
      ref={ref}
      {...props}
      InputProps={InputProps}
    />
  )
})

Reset.propTypes = {
  handleChange: PropTypes.func.isRequired,
  InputProps: PropTypes.object
}

export const handleProps = ({ resettable, ...props }) => {
  const newProps = { ...props }
  delete newProps.dispatch

  newProps.maxDate = newProps.maxDate ? moment(newProps.maxDate) : (newProps.maxDate || undefined)
  newProps.minDate = newProps.minDate ? moment(newProps.minDate) : (newProps.minDate || undefined)
  newProps.value = newProps.value ? moment(newProps.value) : (newProps.value || null)

  newProps.onChange = value => {
    if (props.onChange && (!value || (value && moment(value).isValid()))) {
      props.onChange(value)
    }
  }

  if (!resettable) {
    return newProps
  }

  const slots = props.slots || {}
  const slotProps = props.slotProps || {}

  slots.textField = Reset

  slotProps.textField = {
    handleChange: newProps.onChange,
    foo: 'bar'
  }

  newProps.slots = slots
  newProps.slotProps = slotProps

  return newProps
}
