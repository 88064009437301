import { connect } from 'react-redux'

import ContactManagement from 'components/contact_management'

import {
  getCDeskConfig
} from 'selectors'

import {
  openContactEditDialog,
  fetchAll as onMount,
  openContactsUploadDialog as onImportClick,
  openDistributionListCreateDialog as onCreateListClick
} from 'actions/contact_management'

const mapStateToProps = state => ({
  hasDomain: !!getCDeskConfig(state).get('domain') || false
})

export default connect(
  mapStateToProps,
  {
    onEdit: contact => openContactEditDialog({ isEditMode: false, contact }),
    onMount,
    onImportClick,
    onCreateListClick
  }
)(ContactManagement)
