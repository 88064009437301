import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import InfoIcon from '@mui/icons-material/Info'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { makeStyles } from 'tss-react/mui'
import { Popover, IconButton } from 'containers/themed'
import { Typography, CircularProgress } from '@mui/material'

import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()(theme => ({
  handle: {
    opacity: 0.5,
    cursor: 'pointer',
    transition: 'opacity 225ms',

    '&:hover': {
      opacity: 1
    }
  },
  descriptionPopover: {
    padding: 10,
    maxWidth: 400
  },
  loading: {
    width: 400,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconStack: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  stackInfoIcon: {
    position: 'absolute',
    right: -5,
    top: -8,
    color: theme.palette.grey[900]
  }
}))

export default function Description({ chart, onGenerateDescriptionClick, hasAdminVersion, hasAiSummariesPaidVersion }) {
  const i18n = useI18n()
  const { classes } = useStyles()
  const [open, setOpen] = useState(false)
  const [description, setDescription] = useState(chart.get('description', ''))
  const aiDescriptionEnabled = Boolean(
    chart.getIn(['opts', 'useAiDescription'])
    && onGenerateDescriptionClick
    && (hasAdminVersion || hasAiSummariesPaidVersion)
  )

  useEffect(
    () => {
      if (open && !chart.get('aiDescription') && aiDescriptionEnabled && !chart.get('aiDescriptionLoading')) {
        onGenerateDescriptionClick()
      }
    },
    [
      open,
      onGenerateDescriptionClick,
      aiDescriptionEnabled,
      chart.get('aiDescription'),
      chart.get('aiDescriptionLoading')
    ]
  )

  useEffect(
    () => {
      if (aiDescriptionEnabled) {
        setDescription(chart.get('aiDescription', ''))
      }
    },
    [
      aiDescriptionEnabled,
      chart.get('aiDescription')
    ]
  )

  if ((!aiDescriptionEnabled && !description) || chart.get('loading')) {
    return null
  }

  let title = i18n.get(aiDescriptionEnabled ? 'ai_description' : 'description')

  if (!hasAiSummariesPaidVersion && hasAdminVersion && aiDescriptionEnabled) {
    title = `${title} (${i18n.get('admin_version')})`
  }

  return (
    <Popover
      classes={{ paper: classes.descriptionPopover }}
      onOpenChange={setOpen}
      handle={(
        <IconButton
          classes={{ root: classes.handle }}
          title={title}
          size="large"
        >
          {aiDescriptionEnabled ? (
            <div className={classes.iconStack}>
              <InfoIcon />

              <AutoAwesomeIcon
                fontSize="small"
                classes={{ root: classes.stackInfoIcon }}
              />
            </div>
          ) : <InfoIcon />}
        </IconButton>
      )}
    >
      {aiDescriptionEnabled && (chart.get('aiDescriptionLoading') || !chart.get('aiDescription')) ? (
        <div className={classes.loading}>
          <CircularProgress size={20} />
        </div>
      ) : (
        <Typography
          dangerouslySetInnerHTML={{
            __html: description.replace(/\r?\n\r?/g, '<br />')
          }}
        />
      )}
    </Popover>
  )
}

Description.propTypes = {
  chart: PropTypes.instanceOf(Map).isRequired,
  hasAdminVersion: PropTypes.bool.isRequired,
  hasAiSummariesPaidVersion: PropTypes.bool.isRequired,
  onGenerateDescriptionClick: PropTypes.func
}
