import React from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'
import { List } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import { Grid, LinearProgress, Box } from '@mui/material'

import MediaProfilesItem from 'containers/themax/media_profiles/MediaProfilesItem'

const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: '35px',
    padding: '0px 20px 20px 20px',
    backgroundColor: theme.nrx.paper.backgroundPaperColor,
    borderRadius: '5px'
  },
  mediaProfilesItemWrapper: {
    [theme.breakpoints.only('xs')]: {
      padding: '0 1.5em'
    }
  }
}))

const renderMediaProfile = (mediaProfile, index) => (
  <Grid
    key={index}
    item
    xs={12}
    sm={6}
    md={4}
    lg={3}
    xl={3}
  >
    <MediaProfilesItem mediaProfile={mediaProfile} />
  </Grid>
)

const MediaProfiles = ({
  mediaProfiles,
  mediaProfilesTotal,
  fetchMoreRequestRunning,
  onScrollBottom
}) => {
  const { classes } = useStyles()

  const { size } = mediaProfiles

  if (size === 0) {
    return null
  }

  const moreMediaProfiles = size > 1 && size < mediaProfilesTotal

  const onEnterWaypoint = () => {
    if (moreMediaProfiles) {
      onScrollBottom('publications')
    }
  }

  return (
    <Box
      className={classes.container}
      style={moreMediaProfiles ? { paddingBottom: '300px' } : {}}
    >
      <Grid
        container
        spacing={2}
        className={classes.mediaProfilesItemWrapper}
      >
        {mediaProfiles.slice(0, -1).map((mediaProfile, index) => (
          renderMediaProfile(mediaProfile, index)
        ))}
        <Waypoint
          key="waypoint"
          onEnter={onEnterWaypoint}
        />
        {mediaProfiles.slice(-1).map((mediaProfile, index) => (
          renderMediaProfile(mediaProfile, index)
        ))}

        {fetchMoreRequestRunning && (
          <Grid
            key="loading"
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={3}
          >
            <LinearProgress
              variant="indeterminate"
            />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

MediaProfiles.propTypes = {
  mediaProfiles: PropTypes.instanceOf(List).isRequired,
  mediaProfilesTotal: PropTypes.number.isRequired,
  fetchMoreRequestRunning: PropTypes.bool.isRequired,
  onScrollBottom: PropTypes.func.isRequired
}

export default MediaProfiles
