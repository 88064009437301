import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { fromJS } from 'immutable'
import isEmail from 'validator/lib/isEmail'

import { Input, RaisedPrimaryButton, Dialog, Button } from 'containers/themed'
import { CardContent } from '@mui/material'

import AlphaNavigation from './alpha_navigation'

const maxRenderSize = 350

export default function MailAutocomplete({ i18n, disabled, onChange, value, variant }) {
  const [newValue, setNewValue] = useState('')
  const [invalidAddresses, setInvalidAddresses] = useState([])
  const [deleteConfState, setDeleteConfState] = useState({ open: false, index: null })

  const values = fromJS(value)

  const alpha = values.map(v => v.slice(0, 1).toUpperCase()).toSet().toList().sortBy(a => a)
  const groups = values.groupBy(v => v.slice(0, 1).toUpperCase())

  const handleSubmit = vv => {
    let result = vv.map(v => v.trim())
    result = result.filter(v => v.length)

    let invalid = []

    if (result.length <= maxRenderSize) {
      invalid = result.filter(v => !isEmail(v))
      result = result.filter(v => v && isEmail(v))
    }

    setNewValue('')
    setInvalidAddresses(invalid)

    onChange(result)
  }

  const closeConfDialog = () => setDeleteConfState({ open: false, index: null })

  const renderActions = () => {
    const actions = [
      <Button
        key="cancel"
        onClick={closeConfDialog}
      >
        {i18n.get('no')}
      </Button>,
      <RaisedPrimaryButton
        key="save"
        onClick={() => {
          handleSubmit(value.filter(v => v !== deleteConfState.index))
          closeConfDialog()
        }}
      >
        {i18n.get('yes')}
      </RaisedPrimaryButton>
    ]

    return actions
  }

  return (
    (
      <div>
        {Boolean(invalidAddresses.length) && (
        <div>
          {i18n.get('invalid_mail_addresses')}: {invalidAddresses}
        </div>
        )}
        <div>
          <Input
            disabled={disabled}
            multiline
            rows={3}
            value={newValue}
            label={i18n.get('recipients')}
            hint={i18n.get('email_address_hint')}
            onChange={e => setNewValue(e.target.value)}
            variant={variant}
          />

          <br />
          <br />

          <RaisedPrimaryButton
            disabled={!newValue || disabled}
            onClick={() => handleSubmit(newValue.split(/;|,|\n/).concat(value))}
          >
            {i18n.get('add')}
          </RaisedPrimaryButton>

          <AlphaNavigation
            alpha={alpha}
            groups={groups}
            onDelete={index => setDeleteConfState({ open: true, index })}
          />
          <Dialog
            open={deleteConfState.open}
            title={i18n.get('please_confirm')}
            actions={renderActions()}
            onClose={closeConfDialog}
            maxWidth="sm"
          >
            <CardContent>
              {i18n.get('delete_content')}
            </CardContent>
          </Dialog>
        </div>
      </div>
    )
  )
}

MailAutocomplete.defaultProps = {
  disabled: false
}

MailAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  variant: PropTypes.string,

  i18n: PropTypes.object.isRequired
}
