import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'

import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin'

import ToolbarPlugin from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/plugins/toolbar_plugin'
import StatePlugin from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/plugins/state_plugin'
// Temporary disabled due to the issue with the plugin. Also needs to be adjusted to work only when user input is detected.
// import AutoLinkPlugin from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/plugins/auto_link_plugin'
import MaxLengthPlugin from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/plugins/max_length_plugin'
import EmptyLinkPlugin from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/plugins/empty_link_plugin'
import StoryTextPlugin from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/plugins/story_text_plugin'
import ContactTextPlugin from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/plugins/contact_text_plugin'

import { editorConfig } from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte'

// A helper plugin for debugging which display the editor's nodes as tree structure.
// import TreeViewPlugin from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/plugins/tree_view_plugin'

import './RichTextEditor.css'

const Placeholder = () => <div className="editor-placeholder" />

const RichTextEditor = ({
  editorRef,
  editorState,
  maxLength,
  editorContainerStyle,
  editorStyle,
  editorInnerStyle,
  editorInputStyle,
  setEditorState,
  usePlainText,
  storyTextField,
  contactTextField,
  ...props
}) => {
  const toolbarTrackerRef = useRef()

  return (
    <LexicalComposer
      initialConfig={editorConfig()}
    >
      <div className={`editor-container ${editorContainerStyle || ''}`}>
        <ToolbarPlugin
          toolbarTrackerRef={toolbarTrackerRef}
          {...props}
        />
        <div className={`editor-inner ${editorInnerStyle || ''}`}>
          <RichTextPlugin
            contentEditable={(
              <div className={`editor ${editorStyle || ''}`}>
                <ContentEditable className={`editor-input ${editorInputStyle || ''}`} />
              </div>
            )}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <StatePlugin
            usePlainText={usePlainText}
            editorState={editorState}
            setEditorState={setEditorState}
          />
          {editorRef && <EditorRefPlugin editorRef={editorRef} />}
          <HistoryPlugin />
          <LinkPlugin />
          {/* <AutoLinkPlugin /> */}
          <ListPlugin />
          {maxLength && <MaxLengthPlugin maxLength={maxLength} />}
          <EmptyLinkPlugin />
          {storyTextField && <StoryTextPlugin />}
          {contactTextField && <ContactTextPlugin />}
        </div>
        {/* <TreeViewPlugin /> */}
      </div>
    </LexicalComposer>
  )
}

RichTextEditor.propTypes = {
  editorRef: PropTypes.object,
  editorState: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),
  maxLength: PropTypes.number,
  editorContainerStyle: PropTypes.string,
  editorStyle: PropTypes.string,
  editorInnerStyle: PropTypes.string,
  editorInputStyle: PropTypes.string,
  usePlainText: PropTypes.bool,
  storyTextField: PropTypes.bool,
  contactTextField: PropTypes.bool,

  setEditorState: PropTypes.func.isRequired
}

export default RichTextEditor
