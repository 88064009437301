import React from 'react'
import PropTypes from 'prop-types'
import { Map as ImmutableMap, List as ImmutableList } from 'immutable'
import { useSnackbar } from 'notistack'
import decamelize from 'decamelize'
import useI18n from 'hooks/useI18n'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Divider,
  Grid,
  Box,
  useMediaQuery
} from '@mui/material'

import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'

import { getInvalidChannelUrls } from 'utils/contact_management'
import { capitalize } from 'utils/string'
import { validEmail } from 'utils/regex'

import { IconButton } from 'containers/themed'
import EditContactProfile from './edit_contact_profile'
import EditContactTags from './edit_contact_tags'
import EditContactInfo from './edit_contact_info'
import EditContactChannels from './edit_contact_channels'
import EditDialogActions from './edit_dialog_actions'
import EditContactTopics from './edit_contact_topics/EditContactTopics'
import EditContactLanguages from './edit_contact_languages/EditContactLanguages'
import EditContactMediaTypes from './edit_contact_media_types/EditContactMediaTypes'

export default function ContactEditDialog({
  open,
  data,
  loading,
  isEditMode,
  countries,
  states,
  tags,
  topics,
  mediaTypes,
  languages,
  onClose,
  onSave,
  onChange,
  onUpload,
  onTagChange,
  onTopicChange,
  onLanguageChange,
  onMediaTypeChange,
  onDelete
}) {
  const i18n = useI18n()
  const theme = useTheme()
  const validName = val => !val.match(/[@,`'"[\]()_.~\\]/)
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const requiredFields = ['lastName', 'mail']
  const validateFields = [
    { name: 'mail', isValid: val => validEmail(val.trim()) },
    { name: 'firstName', isValid: val => validName(val || '') },
    { name: 'lastName', isValid: val => validName(val) }
  ]
  const editing = !!data.get('id')

  const handleClose = () => onClose()

  const handleSave = () => {
    if (requiredFields.map(f => !!data.get(f)).some(v => !v)) {
      return enqueueSnackbar(
        `${i18n.get('last_name')}, ${i18n.get('email')} ${i18n.get('is_required')}`,
        {
          variant: 'error',
          preventDuplicate: true
        }
      )
    }

    const invalidSocialMedia = getInvalidChannelUrls(data)

    if (!invalidSocialMedia.isEmpty()) {
      invalidSocialMedia.map(e => enqueueSnackbar(
        `
        ${capitalize(e.key.slice(0, -3))}
        ${i18n.get('widget_url')}
        ${i18n.get('is_not_valid')}
        `, { variant: 'error' }
      ))

      return false
    }

    const validatedList = validateFields.map(x => ({ name: x.name, isValid: x.isValid(data.get(x.name)) }))

    if (validatedList.some(v => !v.isValid)) {
      const message = validatedList
        .map(x => (!x.isValid && `${i18n.get(decamelize(x.name))}`))
        .filter(x => x)
        .join(', ').concat(` ${i18n.get('is_not_valid')}`)

      return enqueueSnackbar(
        message,
        {
          variant: 'error',
          preventDuplicate: true
        }
      )
    }

    closeSnackbar()

    return onSave()
  }

  const handleDelete = () => onDelete([data])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle
        mx={1}
        sx={{
          paddingBottom: t => t.spacing(2),
          color: t => t.palette.grey[800]
        }}
      >
        {editing ? i18n.get('edit_contact') : i18n.get('new_contact')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: t => !t.isDark && t.palette.grey[800]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ overflowX: 'hidden' }}
      >
        <EditContactProfile
          contact={data}
          onChange={onChange}
          onUpload={onUpload}
        />
        <Divider style={{ margin: '1em -24px' }} />
        <Box
          display="flex"
          component="form"
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
          justifyContent="space-between"
          sx={{
            '& .MuiTextField-root': { margin: 0 }
          }}
          gap={2}
        >
          <EditContactInfo
            contact={data}
            countries={countries}
            states={states}
            onChange={onChange}
          />
          <Grid
            container
            height="fit-content"
            rowSpacing={2}
            columnSpacing={2}
          >
            <EditContactTags
              contact={data}
              onChange={onTagChange}
              tags={tags}
            />
            <EditContactTopics
              contact={data}
              onChange={onTopicChange}
              topics={topics}
            />
            <EditContactLanguages
              contact={data}
              languages={languages}
              onChange={onLanguageChange}
              onPreferredLanguageChange={onChange}
            />
            <EditContactMediaTypes
              contact={data}
              mediaTypes={mediaTypes}
              onChange={onMediaTypeChange}
            />
          </Grid>
        </Box>
        <Divider style={{ margin: '1em -24px' }} />
        <EditContactChannels
          contact={data}
          onChange={onChange}
        />
      </DialogContent>
      <DialogActions>
        <EditDialogActions
          loading={loading}
          isEditMode={isEditMode}
          onSave={handleSave}
          onClose={handleClose}
          onDelete={handleDelete}
        />
      </DialogActions>
    </Dialog>
  )
}

ContactEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,

  data: PropTypes.instanceOf(ImmutableMap).isRequired,
  countries: PropTypes.instanceOf(ImmutableList).isRequired,
  states: PropTypes.instanceOf(ImmutableList).isRequired,
  tags: PropTypes.instanceOf(ImmutableList).isRequired,
  topics: PropTypes.instanceOf(ImmutableList).isRequired,
  mediaTypes: PropTypes.instanceOf(ImmutableList).isRequired,
  languages: PropTypes.instanceOf(ImmutableList).isRequired,

  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onTagChange: PropTypes.func.isRequired,
  onTopicChange: PropTypes.func.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  onMediaTypeChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}
