import React from 'react'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import useI18n from 'hooks/useI18n'
import { handleProps } from './utils'

export default function DatePicker(props) {
  const i18n = useI18n()
  const newProps = handleProps(props)

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDatePicker
        autoOk
        clearable
        {...newProps}
        localeText={{
          cancelButtonLabel: i18n.get('cancel'),
          clearButtonLabel: i18n.get('reset')
        }}
      />
    </LocalizationProvider>
  )
}
