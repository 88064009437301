/* global window */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  IconButton,
  Typography,
  Divider,
  Tooltip
} from '@mui/material'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

import KeyFigure from 'components/key_figure'
import BlurredSection from 'components/themax/hidden_content/BlurredSection'
import LockedContentTooltip from 'components/themax/hidden_content/LockedContentTooltip'
import { shorten } from 'utils/string'

const useStyles = makeStyles()((theme, { hasEditorialPlan }) => ({
  card: {
    cursor: hasEditorialPlan ? 'pointer' : 'default',
    '&:hover': {
      boxShadow: '0 5px 15px rgba(0,0,0,0.3)'
    }
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px'
  },

  cardContent: {
    minHeight: '100px'
  },
  logoContainer: {
    maxHeight: '20px',
    maxWidth: '100px',
    height: 'auto',
    width: 'auto'
  },
  publicationSpacer: {
    height: '20px',
    width: '10opx'
  },
  dividerContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  divider: {
    width: 'calc(100% - 30px)'
  },
  cardAvatar: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    paddingBottom: 0
  },
  spacer: {
    flex: 1
  }
}))

const MediaProfilesItem = ({
  mediaProfile,
  hasPaidVersion,
  openDetailedViewDialog,
  setSelectedEditorialPlan,
  fetchRelatedEditorialPlans
}) => {
  const i18n = useI18n()
  const [imgError, setImgError] = useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const latestEditorialPlan = mediaProfile.get('editorialPlans').first()
  const { classes } = useStyles({ hasEditorialPlan: !!latestEditorialPlan })

  const handlePfdClick = e => {
    e.preventDefault()
    e.stopPropagation()

    window.open(latestEditorialPlan.get('publicationFile'), '_blank', 'noopener noreferrer')
  }

  const renderMediaProfilePDF = () => {
    if (!hasPaidVersion) {
      return (
        <>
          <IconButton disabled>
            <PictureAsPdfIcon />
          </IconButton>
          <LockedContentTooltip />
        </>
      )
    }

    if (latestEditorialPlan && latestEditorialPlan.get('publicationFile') === null) {
      return (
        <IconButton disabled>
          <PictureAsPdfIcon />
        </IconButton>
      )
    }

    if (!latestEditorialPlan) {
      return (
        <Tooltip
          title={i18n.get('themax_no_editorial_plans')}
          placement="left"
          arrow
        >
          <IconButton
            disabled
            sx={{
              '&.Mui-disabled': {
                pointerEvents: 'auto'
              }
            }}
          >
            <PictureAsPdfIcon />
          </IconButton>
        </Tooltip>
      )
    }

    return (
      <IconButton
        disabled={!latestEditorialPlan}
        onClick={handlePfdClick}
      >
        <PictureAsPdfIcon />
      </IconButton>
    )
  }

  const toggleShowMore = e => {
    e.preventDefault()

    setSelectedEditorialPlan(latestEditorialPlan)
    fetchRelatedEditorialPlans()
    openDetailedViewDialog()
  }

  const formatPublicationName = () => {
    const publicationName = mediaProfile.get('name')
    const maxLength = 28

    return publicationName.length > maxLength ? (
      <Tooltip
        title={publicationName}
        arrow
      >
        <Typography
          fontSize={19}
          fontWeight={500}
        >
          {shorten(publicationName, maxLength)}
        </Typography>
      </Tooltip>
    ) : (
      <Typography
        fontSize={19}
        fontWeight={500}
      >
        {publicationName}
      </Typography>
    )
  }

  let avatar = (
    <div className={classes.publicationSpacer}>
      <img
        src={mediaProfile.get('logoUrl')}
        alt={mediaProfile.get('name')}
        className={classes.logoContainer}
        style={{ display: isImageLoaded ? 'block' : 'none' }}
        onLoad={() => setIsImageLoaded(true)}
        onError={() => setImgError(true)}
      />
      {imgError && (
        null
      )}
    </div>
  )

  if (imgError) {
    avatar = <div className={classes.publicationSpacer} />
  }

  return (
    <Card
      className={classes.card}
      onClick={latestEditorialPlan ? toggleShowMore : null}
    >
      <CardHeader
        avatar={(
          hasPaidVersion ? (
            <div className={classes.cardAvatar}>
              {avatar}
              {formatPublicationName()}
            </div>
          ) : (
            <BlurredSection />
          )
        )}
      />
      <div className={classes.dividerContainer}>
        <Divider className={classes.divider} />
      </div>
      <CardContent className={classes.cardContent}>
        <Grid
          container
        >
          <Grid
            item
            xl={3}
            lg={6}
            md={6}
            sm={6}
            xs={12}
          >
            <KeyFigure
              name={i18n.get('reach')}
              value={mediaProfile.get('reach') || 0}
            />
          </Grid>
          <Grid
            item
            xl={3}
            lg={6}
            md={6}
            sm={6}
            xs={12}
          >
            <KeyFigure
              name={i18n.get('page_ave')}
              value={mediaProfile.get('pageAve') || 0}
            />
          </Grid>
          <Grid
            item
            xl={3}
            lg={6}
            md={6}
            sm={6}
            xs={12}
          >
            <KeyFigure
              name={i18n.get('printrun')}
              value={mediaProfile.get('printrun') || 0}
            />
          </Grid>
          <Grid
            item
            xl={3}
            lg={6}
            md={6}
            sm={6}
            xs={12}
          >
            <KeyFigure
              name={i18n.get('circulation')}
              value={mediaProfile.get('distributedCirculation') || 0}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <div className={classes.spacer} />
        {
          renderMediaProfilePDF()
        }
      </CardActions>
    </Card>
  )
}

MediaProfilesItem.propTypes = {
  mediaProfile: PropTypes.instanceOf(Map).isRequired,
  hasPaidVersion: PropTypes.bool.isRequired,

  openDetailedViewDialog: PropTypes.func.isRequired,
  setSelectedEditorialPlan: PropTypes.func.isRequired,
  fetchRelatedEditorialPlans: PropTypes.func.isRequired
}

export default MediaProfilesItem
