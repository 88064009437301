import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { List, Map, fromJS } from 'immutable'
import { debounce } from 'throttle-debounce'
import { useSnackbar } from 'notistack'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'

import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Box,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { green } from '@mui/material/colors'

import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseIcon from '@mui/icons-material/CloseRounded'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'

import { BrowseButton, Button, Select, RaisedPrimaryButton } from 'containers/themed'

import { formatBytes } from 'utils/number'
import { getMediaFileSrcAndFit } from 'utils/content_desk'
import { Document, Page } from 'utils/pdf'
import { UploadedMediaFilesSizeLimit } from 'static/constants'

const useStyles = makeStyles()({
  uploadButton: {
    width: '205px',
    height: '56px'
  },
  mediaListBox: {
    height: 580
  },
  loadMoreButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: '180px !important'
  },
  attachmentsActions: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  selectWrapper: {
    width: '200px'
  },
  pdfPaging: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    bottom: 'calc(100% - 50px)',
    left: 0,
    right: 0,
    marginBottom: 20
  },
  pdfPagingButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
})

const allowedMimeTypes = [
  'image/*',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv'
]

const allowedTotalAttachmentsSize = 12582912 // 12MB
const allowedTotalAttachments = 3

const Attachments = ({
  contentFormData,
  uploadedMediaFiles,
  loadMoreDisabled,
  requestRunning,
  mediaFileUploading,
  uploadedMediaFilesTotalSize,
  fetchMore,
  onMediaDelete,
  onChange,
  onUpload
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const [filesFilter, setFilesFilter] = useState('')
  const [hoveredMediaId, setHoveredMediaId] = useState(null)
  const [deleteState, setDeleteState] = useState({ mediaId: null, open: false })
  const { enqueueSnackbar } = useSnackbar()
  const [totalPages, setTotalPages] = useState({})
  const [pageNumbers, setPageNumbers] = useState({})

  const mediaTypeOptions = [
    { value: 'all', label: i18n.get('all') },
    { value: 'image', label: i18n.get('image') },
    { value: 'document', label: i18n.get('document') }
  ]

  const currentSelectedMediaFiles = contentFormData.get('ccdMediaFiles')
  const currentSelectedMediaFileIds = currentSelectedMediaFiles.map(f => f.get('id'))
  const currentAttachmentsSize = currentSelectedMediaFiles.reduce((acc, selectedMediaFile) => acc + selectedMediaFile.get('size'), 0)

  const onDocumentLoadSuccess = (documentId, { numPages }) => {
    setPageNumbers(prevPageNumbers => ({
      ...prevPageNumbers,
      [documentId]: 1
    }))
    setTotalPages(prevTotalPages => ({
      ...prevTotalPages,
      [documentId]: numPages
    }))
  }

  const changePage = (documentId, offset) => {
    setPageNumbers(prevPageNumbers => ({
      ...prevPageNumbers,
      [documentId]: prevPageNumbers[documentId] + offset
    }))
  }
  const previousPage = (documentId, e) => {
    e.stopPropagation()
    changePage(documentId, -1)
  }
  const nextPage = (documentId, e) => {
    e.stopPropagation()
    changePage(documentId, 1)
  }

  function filterMediaFiles() {
    if (filesFilter === 'image') {
      return uploadedMediaFiles.filter(f => f.get('mimeType').startsWith('image'))
    }

    if (filesFilter === 'document') {
      return uploadedMediaFiles.filter(f => !f.get('mimeType').startsWith('image'))
    }

    return uploadedMediaFiles
  }

  const mediaFiles = filterMediaFiles()

  const onMediaUpload = debounce(50, file => {
    let doUpload = true

    if (!file) {
      doUpload = false
    }

    if (file && ((file.size + uploadedMediaFilesTotalSize) > UploadedMediaFilesSizeLimit)) {
      doUpload = false

      enqueueSnackbar(
        `${i18n.get('exceeding_upload_storage_size_warning')}`,
        { variant: 'error', preventDuplicate: true }
      )
    }

    if (doUpload) {
      onUpload({ file })
    }
  })

  const exceedsSizeLimit = (deselecting, selectedMediaFile) => {
    if (deselecting) {
      return false
    }

    return allowedTotalAttachmentsSize < (currentAttachmentsSize + selectedMediaFile.get('size'))
  }

  const exceedsFileLimit = deselecting => {
    if (!deselecting && currentSelectedMediaFileIds.size + 1 > allowedTotalAttachments) {
      return true
    }

    return false
  }

  const toggleMediaSelect = selectedMediaFile => {
    const deselecting = currentSelectedMediaFileIds.includes(selectedMediaFile.get('id'))

    if (exceedsFileLimit(deselecting)) {
      return enqueueSnackbar(
        `${i18n.get('attachments_file_limit_warning', { number: allowedTotalAttachments })}`,
        { variant: 'error', preventDuplicate: true }
      )
    }

    if (exceedsSizeLimit(deselecting, selectedMediaFile)) {
      return enqueueSnackbar(
        `${i18n.get('attachments_size_limit_warning', { size: formatBytes(allowedTotalAttachmentsSize) })}`,
        { variant: 'error', preventDuplicate: true }
      )
    }

    let newSelected = List()

    if (deselecting) {
      newSelected = currentSelectedMediaFiles.filter(selected => selected.get('id') !== selectedMediaFile.get('id'))
    } else {
      newSelected = currentSelectedMediaFiles.unshift(fromJS(selectedMediaFile))
    }

    return onChange({ key: 'ccdMediaFiles', value: newSelected })
  }

  const handleOpenDeleteDialog = id => setDeleteState({ mediaId: id, open: true })
  const handleCloseDeleteDialog = () => setDeleteState({ mediaId: null, open: false })

  const handleDeleteMedia = () => {
    onMediaDelete({ id: deleteState.mediaId })
    onChange({
      key: 'ccdMediaFiles',
      value: currentSelectedMediaFiles
        .filter(selected => selected.get('id') !== deleteState.mediaId)
    })
    handleCloseDeleteDialog()
  }

  const renderFileImage = mf => {
    const { src, style } = getMediaFileSrcAndFit(mf)

    if (mf.get('mimeType') === 'application/pdf') {
      const documentId = mf.get('id')

      return (
        <div style={{ position: 'relative', marginTop: 40 }}>
          <Document
            file={mf.get('url')}
            onClick={_e => toggleMediaSelect(mf)}
            onLoadSuccess={props => onDocumentLoadSuccess(documentId, props)}
          >
            <Page
              pageNumber={pageNumbers[documentId] || 1}
              scale={0.2}
              width={1670}
            />
            <div className={classes.pdfPaging}>
              <p>
                {i18n.get('page')} {pageNumbers[documentId]
                  || (totalPages[documentId] ? 1 : '--')} {i18n.get('of')} {totalPages[documentId] || '--'}
              </p>
              {(totalPages[documentId] !== 1) && (
                <div>
                  <IconButton
                    disabled={pageNumbers[documentId] <= 1}
                    onClick={e => previousPage(documentId, e)}
                    title={i18n.get('previous')}
                    size="small"
                    className={classes.pdfPagingButton}
                    disableRipple
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                  <IconButton
                    disabled={pageNumbers[documentId] >= totalPages[documentId]}
                    onClick={e => nextPage(documentId, e)}
                    size="small"
                    className={classes.pdfPagingButton}
                    disableRipple
                  >
                    <NavigateNextIcon />
                  </IconButton>
                </div>
              )}
            </div>
          </Document>
        </div>
      )
    }

    return (
      /* eslint-disable-next-line */
      (<img
        onClick={_e => toggleMediaSelect(mf)}
        src={src}
        srcSet={src}
        alt={mf.get('id')}
        loading="lazy"
        style={style}
      />)
    )
  }

  const renderFilesGallery = () => (
    <Box
      className={classes.mediaListBox}
    >
      <ImageList
        cols={5}
        style={{ height: 580 }}
      >
        {mediaFiles.map(mf => (
          <ImageListItem
            key={mf.get('id')}
            onMouseEnter={() => setHoveredMediaId(mf.get('id'))}
            onMouseLeave={() => setHoveredMediaId(null)}
          >
            {(hoveredMediaId === mf.get('id')) && (
              <ImageListItemBar
                style={{ zIndex: 9999 }}
                position="bottom"
                title={(
                  <Tooltip title={mf.get('filename')}>
                    <span>{mf.get('filename')}</span>
                  </Tooltip>
                )}
                subtitle={formatBytes(mf.get('size'))}
                actionIcon={(
                  <IconButton
                    sx={{ color: 'red' }}
                    onClick={() => handleOpenDeleteDialog(mf.get('id'))}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
                actionPosition="right"
              />
            )}
            {currentSelectedMediaFileIds.includes(mf.get('id'))
              && (
                <ImageListItemBar
                  sx={{ background: 'transparent', zIndex: 9999 }}
                  position="top"
                  actionPosition="left"
                  actionIcon={<CheckCircleRoundedIcon sx={{ color: green[300] }} />}
                />
              )}
            {renderFileImage(mf)}
          </ImageListItem>
        ))}
        <ImageListItem
          key="loadMoreButton"
          className={classes.loadMoreButton}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={loadMoreDisabled}
            onClick={fetchMore}
            loading={requestRunning}
          >{i18n.get('load_more')}
          </Button>
        </ImageListItem>
      </ImageList>
    </Box>
  )

  const renderDeleteDialog = () => (
    <Dialog
      open={deleteState.open}
      onClose={handleCloseDeleteDialog}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle
        sx={{
          paddingBottom: t => t.spacing(3),
          color: t => t.palette.grey[800]
        }}
      >
        {i18n.get('are_you_sure')}
        <IconButton
          aria-label="close"
          onClick={handleCloseDeleteDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: t => !t.isDark && t.palette.grey[800]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ overflowX: 'hidden', padding: t => t.spacing(3) }}
      >
        <Typography
          mt={2}
          variant="body2"
        >{i18n.get('delete_file_text')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box
          m={2}
          sx={{
            '& .MuiButtonBase-root': { marginLeft: 2 }
          }}
        >
          <Button
            onClick={handleCloseDeleteDialog}
          >
            {i18n.get('close')}
          </Button>
          <RaisedPrimaryButton
            onClick={handleDeleteMedia}
          >
            {i18n.get('delete')}
          </RaisedPrimaryButton>
        </Box>
      </DialogActions>
    </Dialog>
  )

  return (
    <>
      <div className={classes.attachmentsActions}>
        <BrowseButton
          className={classes.uploadButton}
          accept={allowedMimeTypes.toString()}
          color="primary"
          variant="outlined"
          size="large"
          loading={mediaFileUploading}
          onChange={e => onMediaUpload(e.target.files[0])}
        >
          <ImageOutlinedIcon />&nbsp;{i18n.get('upload_media')}
        </BrowseButton>
        <div className={classes.selectWrapper}>
          <Select
            variant="outlined"
            onChange={({ value: v }) => setFilesFilter(v)}
            options={mediaTypeOptions}
            value={filesFilter}
            label={i18n.get('media_type')}
          />
        </div>
      </div>
      {renderFilesGallery()}
      {renderDeleteDialog()}
    </>
  )
}

Attachments.propTypes = {
  contentFormData: PropTypes.instanceOf(Map).isRequired,
  uploadedMediaFiles: PropTypes.instanceOf(List).isRequired,
  loadMoreDisabled: PropTypes.bool.isRequired,
  requestRunning: PropTypes.bool.isRequired,
  mediaFileUploading: PropTypes.bool.isRequired,
  uploadedMediaFilesTotalSize: PropTypes.number.isRequired,

  fetchMore: PropTypes.func.isRequired,
  onMediaDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired
}

export default Attachments
